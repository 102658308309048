import { Stack, Typography } from '@mui/material';
import CustomButton from '../../../../../../components/button/CustomButton';

const SellerEnterprisePlan = () => (
  <Stack
    alignItems="center"
    justifyContent="space-between"
    width="100%"
    height="100%"
    minHeight={{ xs: '700px', lg: '100%' }}
  >
    <Stack gap={2} alignItems="center" width="100%">
      <Typography variant="publicHeading4" color="primaryLight.500">
        Enterprise
      </Typography>
      <Typography variant="publicBody1" color="primaryDark.900" align="center">
        Custom
      </Typography>
      <Stack gap={1} width={{ xs: '100%', lg: '65%' }} mt={4}>
        <Typography align="center" variant="publicBody5Light" color="primaryDark.900" my={1}>
          Have custom requirements, or need extra support?
        </Typography>
        <Typography align="center" variant="publicBody5Light" color="primaryDark.900" my={1}>
          Contact us to discuss a solution tailored to your company's needs
        </Typography>
      </Stack>
    </Stack>
    <CustomButton sx={{ mt: 5 }} href={process.env.REACT_APP_CALENDLY_URL as string}>
      Contact us
    </CustomButton>
  </Stack>
);

export default SellerEnterprisePlan;
