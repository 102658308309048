import { CircularProgress, Stack } from '@mui/material';
import { primaryLight } from '../../../../color';

const BlogSpinner = () => (
  <Stack sx={{ py: '5rem' }} direction="row" alignItems="center" justifyContent="center">
    <CircularProgress sx={{ color: primaryLight[300] }} />
  </Stack>
);

export default BlogSpinner;
