import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as Check } from '../../../../assets/icons/check-icon.svg';
import { display } from '@mui/system';

type Props = {
  title: string;
  hideCheck?: boolean;
};

const CheckedItem = ({ title, hideCheck }: Props) => (
  <Stack direction="row" alignItems="center" gap={1.5}>
    <Box sx={{ width: 24, height: 24 }}>
      <Check style={{ display: hideCheck ? 'none' : 'unset' }} />
    </Box>
    <Typography variant="publicBody4Regular" color="primaryDark.900">
      {title}
    </Typography>
  </Stack>
);

export default CheckedItem;
