import { Stack } from '@mui/material';
import LAYOUT_DEFAULTS from '../../../../../layout/utils';
import PricingCards from '../PricingCard';
import LogoSection from '../LogoSection';
import PricingFaq from '../PricingFaq';
import GetDemoSection from '../GetDemoSection/GetDemoSection';

import SellerStandardPlan from './components/SellerStandardPlan';
import SellerEnterprisePlan from './components/SellerEnterprisePlan';

const SellerPricingContent = () => (
  <>
    <Stack sx={{ ...LAYOUT_DEFAULTS }} alignItems="center">
      <Stack
        direction={{ md: 'row' }}
        alignItems={{ xs: 'center', md: 'stretch' }}
        width="100%"
        minHeight="100%"
        maxWidth="932px"
        my={8}
        gap={{ xs: 5, md: 0 }}
        sx={{ mr: { md: '-32px' } }}
      >
        <PricingCards
          purple
          sx={{ mr: { md: '-32px' }, width: { xs: '100%', md: '50%' }, maxWidth: '500px' }}
        >
          <SellerStandardPlan />
        </PricingCards>
        <PricingCards sx={{ width: { xs: '100%', md: '50%' }, maxWidth: '500px' }}>
          <SellerEnterprisePlan />
        </PricingCards>
      </Stack>
      <LogoSection />
      <PricingFaq />
    </Stack>
    <GetDemoSection type="manage" />
  </>
);
export default SellerPricingContent;
