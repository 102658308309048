import { Stack, Typography } from '@mui/material';

import CheckedItem from '../../CheckedItem';
import CustomButton from '../../../../../../components/button/CustomButton';

const SellerStandardPlan = () => (
  <Stack gap={0.5} alignItems="center" width="100%" justifyContent="space-between">
    <Typography variant="publicHeading4" color="primaryLight.500" sx={{ mb: 1 }}>
      Standard
    </Typography>

    <Typography
      variant="publicBody1"
      color="primaryDark.900"
      display="flex"
      justifyContent="center"
      alignItems="center"
      align="center"
      gap={1}
    >
      $960
      <Typography component="span" variant="publicBody5Light" color="primaryDark.900">
        annually
      </Typography>
    </Typography>

    <Typography
      variant="publicBody5Light"
      color="primaryDark.900"
      display="flex"
      justifyContent="center"
      alignItems="center"
      align="center"
      gap={1}
    >
      or $100 monthly
    </Typography>

    <Typography variant="publicBody3" color="primaryDark.900" my={2}>
      Try for free for 30 days
    </Typography>

    <Stack gap={2} width="80%">
      <CheckedItem title="Unlimited projects" />
      <CheckedItem title="Built-in chat & video calls" />
      <CheckedItem title="Company calendar" />
      <CheckedItem title="Notifications" />
      <CheckedItem title="Ready-to-use contracts" />
      <CheckedItem title="Milestone & task management" />
      <CheckedItem title="Payment processing" />
      <CheckedItem title="Expense tracking" />
      <CheckedItem title="Priority support" />
      <CheckedItem title="Dedicated account manager" />
    </Stack>

    <CustomButton sx={{ mt: 5 }} href={process.env.REACT_APP_CALENDLY_URL as string}>
      Try for free
    </CustomButton>
  </Stack>
);

export default SellerStandardPlan;
