import { OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useCustomInputContext } from '../CustomInputContext';

const CustomInputText = () => {
  const {
    field,
    hasError,
    startAdornment,
    adornment,
    placeholder,
    type,
    disabled,
    onBlur,
    onKeyUp,
    multiline,
    rows,
  } = useCustomInputContext();
  return (
    <OutlinedInput
      {...field}
      onBlur={onBlur}
      placeholder={placeholder}
      disabled={disabled}
      type={type}
      multiline={multiline}
      rows={rows}
      startAdornment={
        startAdornment?.text && (
          <InputAdornment disableTypography sx={{ ...startAdornment.sx }} position="start">
            {startAdornment.text}
          </InputAdornment>
        )
      }
      endAdornment={
        hasError || adornment?.icon ? (
          <InputAdornment position="end">
            {hasError && <ErrorIcon sx={{ color: 'secondaryPink.500' }} />}

            {adornment?.icon && (
              <IconButton onClick={adornment?.onClick}>{adornment?.icon}</IconButton>
            )}
          </InputAdornment>
        ) : null
      }
      onKeyUp={onKeyUp}
    />
  );
};

export default CustomInputText;
