import { useState } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BlogDto } from 'tdc-web-backend/blogs/schemas';
import { EnumBlogStatus } from 'tdc-web-backend/enums/enums';
import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns';
import CustomButton from '../../../../components/button/CustomButton';
import ActionPanelLine from '../../../../components/action-panel/ActionPanelLine';
import { primaryDark, secondaryBlue } from '../../../../color';
import { ReactComponent as MapPinIcon } from '../../../../assets/icons/geo.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { ReactComponent as CalendarCheckIcon } from '../../../../assets/icons/calendar-check.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye-fill.svg';
import { ReactComponent as PersonFillIcon } from '../../../../assets/icons/person-fill.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/layout-icons/TrashIcon.svg';
import ConfirmModal from '../../../../components/confirm-modal/ConfirmModal';

type ActionPanelProps =
  | {
      viewMode?: false | undefined;
      blogData: BlogDto | undefined;
      onPublish: () => void;
      saveAsDraft: () => void;
      onDelete: () => void;
      isLoading?: boolean;
    }
  | {
      viewMode: true;
      blogData: BlogDto | undefined;
      onPublish?: () => void;
      saveAsDraft?: () => void;
      onDelete: () => void;
      isLoading?: boolean;
    };
const ActionPanel = ({
  viewMode,
  blogData,
  onPublish,
  saveAsDraft,
  onDelete,
  isLoading,
}: ActionPanelProps) => {
  const navigate = useNavigate();
  const form = useFormContext();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const enableDraft = blogData?.status !== EnumBlogStatus.Published && !viewMode;
  const justifyContent = enableDraft ? 'space-between' : 'flex-end';

  return (
    <>
      <Stack
        divider={<Divider />}
        sx={{
          borderBottomWidth: '1px',
          borderBottomStyle: ' solid',
          borderBottomColor: primaryDark[250],
          padding: '3rem 1.5rem',
        }}
        spacing={0}
      >
        <Stack direction="row" sx={{ mb: '1rem' }} spacing={2}>
          <CustomButton
            variant="primary"
            sx={{ height: '2.8125rem', width: '100%' }}
            onClick={onPublish}
            loading={isLoading}
            disabled={blogData?.status === EnumBlogStatus.Published && viewMode}
          >
            publish
          </CustomButton>
          {!viewMode && (
            <CustomButton
              variant="primaryLight"
              sx={{ height: '2.8125rem' }}
              onClick={() => navigate('../view', { relative: 'path' })}
            >
              cancel
            </CustomButton>
          )}
        </Stack>
        <Stack direction="column" divider={<Divider />}>
          <ActionPanelLine
            icon={<PersonFillIcon fill={primaryDark[500]} />}
            label="created by"
            value={`${blogData?.createdBy.firstName || ''} ${blogData?.createdBy.lastName || ''}`}
          />
          {viewMode ? (
            <ActionPanelLine
              icon={<EyeIcon fill={primaryDark[500]} />}
              label="visibility"
              value={blogData?.visibility ? 'Public' : 'Private'}
            />
          ) : (
            <ActionPanelLine
              icon={<EyeIcon fill={primaryDark[500]} />}
              label="visibility"
              value={form.getValues('visibility')}
              type="radio"
              onChange={(e) => {
                form.setValue('visibility', !!(e === 'true' || e === true));
                form.trigger('visibility');
              }}
            />
          )}
          <ActionPanelLine
            icon={<MapPinIcon fill={primaryDark[500]} />}
            label="status"
            value={blogData?.status}
            type="chip"
          />
          <ActionPanelLine
            icon={<CalendarIcon fill={primaryDark[500]} />}
            label="date created"
            value={blogData?.created ? format(new Date(blogData.created), 'MM.dd.yyyy') : ''}
          />
          <ActionPanelLine
            icon={<CalendarCheckIcon fill={primaryDark[500]} />}
            label="last edited"
            value={blogData?.updated ? format(new Date(blogData.updated), 'MM.dd.yyyy') : ''}
          />
        </Stack>
        <Stack direction="row" justifyContent={justifyContent} spacing={2} sx={{ mt: '1rem' }}>
          {enableDraft && (
            <CustomButton
              variant="secondaryDark"
              sx={{ height: '2.8125rem' }}
              onClick={saveAsDraft}
              loading={isLoading}
            >
              Save as Draft
            </CustomButton>
          )}
          {blogData?.status && (
            <CustomButton
              variant="secondaryDark"
              sx={{ height: '2.8125rem' }}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              delete
            </CustomButton>
          )}
        </Stack>
      </Stack>
      <ConfirmModal
        width="50%"
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        title="Delete blog"
        message={
          <Typography color="primaryDark.500" lineHeight="21px">
            Are you sure you want to permanently delete this blog?
          </Typography>
        }
        confirmButtonText="Confirm"
        onConfirm={() => {
          setIsDeleteModalOpen(false);
          onDelete();
        }}
        iconProps={{
          icon: <TrashIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
          wrapperBackgroundColor: secondaryBlue[100],
        }}
        showCancelButton
        onCancel={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
};

export default ActionPanel;
