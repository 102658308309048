import { Box, Stack, Typography } from '@mui/material';
import { BlogDto } from 'tdc-web-backend/blogs/schemas';
import { BlogCategoryDto } from 'tdc-web-backend/blog-categories/schemas';
import { format } from 'date-fns';
import BasicInfo from './components/BasicInfo';

type HeadingProps = {
  blog: BlogDto;
};

const Heading = ({ blog }: HeadingProps) => (
  <Stack spacing={3}>
    <Typography variant="publicHeading2" sx={{ fontSize: { xs: '3rem', md: '4rem' } }}>
      {blog?.headline}
    </Typography>
    <Typography
      variant="publicBody2Regular"
      sx={{
        letterSpacing: '0.06rem',
        lineHeight: '2rem',
        fontSize: { xs: '1.25rem', md: '1.5rem' },
      }}
    >
      {blog?.leadText}
    </Typography>
    <Stack direction="row" justifyContent="center">
      <Box
        component="img"
        maxWidth="100%"
        src={blog?.file}
        //   height="100%"
        sx={{ objectFit: 'contain' }}
      />
    </Stack>
    <BasicInfo
      author={`${blog.author?.firstName} ${blog.author?.firstName}`}
      publishDate={blog?.published ? format(new Date(blog?.published), 'MM.dd.yyyy') : ''}
      categories={blog.categories?.map((category: BlogCategoryDto) => category.name)}
    />
  </Stack>
);

export default Heading;
