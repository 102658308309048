import { Chip, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectProps } from '@mui/material/Select/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import ErrorIcon from '@mui/icons-material/Error';
import BaseInputLabel from './BaseInputLabel';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { primaryDark, secondaryBlue, secondaryGreen, secondaryPink } from '../../color';
import CustomChip from '../custom-chip/CustomChip';

type Props<T> = {
  name: string;
  displayElementsExternally?: (value: any, index?: number) => JSX.Element | Array<JSX.Element>;
  required?: boolean;
  shrink?: boolean;
  choices: Map<string | number, string | any> | Array<T>;
  displayEntityNames?: string;
  // use this prop in conjunction with choices when choices is instanceof Array
  arrayPropName?: string;
  arrayPropId?: string;
  // Use this prop to make custom menu items
  renderCustomMenuItem?: (data: T) => JSX.Element;
  renderCustomSelectedValue?: (data: T) => JSX.Element;
  withChips?: boolean;
  transformLabelFn?: (data: any) => string;
} & SelectProps;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },

  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const ControlledSelectInput = <T,>({
  name,
  required,
  choices,
  shrink,
  displayElementsExternally,
  displayEntityNames,
  arrayPropName = 'name',
  renderCustomSelectedValue,
  arrayPropId = 'id',
  label,
  renderCustomMenuItem,
  withChips,
  placeholder,
  transformLabelFn,
  ...props
}: Props<T>) => {
  const { register, control, getFieldState, formState, getValues, setValue, trigger } =
    useFormContext();

  const fieldState = getFieldState(name);
  const showError = _.get(formState.errors, name) && fieldState.isTouched;
  const fieldValue = getValues(name);

  console.log(name);
  console.log(fieldValue);
  console.log(fieldState);
  console.log(showError);
  console.log('----------');

  const styles = {
    margin: '0 0 24px 0',
    '.MuiModal-root': {
      position: 'absolute',
      zIndex: 1,
      '.MuiPaper-root': {
        top: '48px !important',
        left: '0 !important',
      },
    },
    '.MuiOutlinedInput-root': {
      border: `1px solid ${showError ? secondaryPink[100] : secondaryBlue[100]}`,
      color: props.disabled || withChips ? 'primaryDark.300' : 'primaryDark.600',
      backgroundColor: showError ? 'secondaryPink.100' : 'transparent',
      fontSize: '14px',
      '&:not(.Mui-disabled)': {
        '&:hover': {
          backgroundColor: 'primaryDark.150',
        },
      },
    },
    '.MuiSelect-select': {
      padding: '0.844rem 0.75rem',
    },
    '.Mui-focused': {
      borderColor: 'secondaryYellow.500',
      boxShadow: '6px 6px 15px rgba(56, 69, 114, 0.1)',
    },
    '.MuiFormHelperText-root': {
      marginTop: 0,
      marginLeft: 0,
      boxShadow: 'none !important',
    },
    fieldset: {
      border: '0 !important',
    },
    '.MuiMenuItem-root': {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '16px',
      color: 'primaryDark.500',
      fontFamily: 'Gellix-SemiBold',
      padding: '8px 16px',
      '&:hover': {
        color: 'primaryDark.600 !important',
      },
      svg: {
        display: 'none', // hide check icon
      },
    },
    '.Mui-selected': {
      backgroundColor: 'transparent !important',
      color: 'primaryDark.600',
      '&:hover': {
        backgroundColor: 'primaryDark.200 !important',
      },
      svg: {
        display: 'block', // show check icon
      },
    },
    '.Mui-disabled': {
      backgroundColor: 'primaryDark.200',
      color: 'primaryDark.300',
      border: 'none',
    },
  };

  return (
    <Stack
      sx={{
        ...props.sx,
        ...styles,
      }}
    >
      {label && (
        <BaseInputLabel
          sx={{ color: props.disabled ? 'primaryDark.300' : 'primaryDark.500' }}
          text={label as string}
          required={required}
        />
      )}
      <Select
        {...register(name, required ? { required: 'This field is required' } : { required: false })}
        variant="outlined"
        displayEmpty
        value={useWatch({
          control,
          name,
        })}
        IconComponent={ExpandMoreIcon}
        endAdornment={
          showError ? (
            <ErrorIcon
              sx={{
                color: 'secondaryPink.500',
                mr: 3.5,
              }}
            />
          ) : null
        }
        MenuProps={{
          disablePortal: true,
          disableScrollLock: true,
          keepMounted: false,
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
              boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
              borderRadius: '8px',
            },
          },
        }}
        renderValue={(selected) => {
          const transform = transformLabelFn ?? ((x) => x);

          // because we return placeholder as a renderValue
          // I had to make a workaround and create additional div

          //second condition is set if multiple key is passed
          if ((!selected && placeholder) || (!selected?.length && placeholder)) {
            return <div style={{ color: primaryDark[400] }}>{placeholder}</div>;
          }
          if (displayElementsExternally) {
            return null;
          }
          if (choices instanceof Array && arrayPropName) {
            if (props.multiple) {
              return selected
                .map((item: any) =>
                  transform(
                    choices[
                      choices.findIndex((choice) => choice[arrayPropId as keyof T] === item)
                    ]?.[arrayPropName as keyof T],
                  ),
                )
                .join(', ');
            }

            if (renderCustomSelectedValue) {
              return renderCustomSelectedValue(
                choices[choices.findIndex((choice) => choice[arrayPropId as keyof T] === selected)],
              );
            }

            return transform(
              choices[choices.findIndex((choice) => choice[arrayPropId as keyof T] === selected)]?.[
                arrayPropName as keyof T
              ],
            );
          }

          if (choices instanceof Map) {
            if (props.multiple) {
              return transform(selected.map((item: any) => choices.get(item)).join(', '));
            }

            return transform(choices.get(selected));
          }
        }}
        {...props}
      >
        {(() => {
          if (choices instanceof Map) {
            return Array.from(choices, ([key, value]: [string | number, string]) => (
              <MenuItem key={key} value={key}>
                {/* {renderCustomMenuItem ? renderCustomMenuItem(value) :  */}
                {transformLabelFn ? transformLabelFn(value) : value}
                <CheckIcon fill={secondaryGreen[700]} />
              </MenuItem>
            ));
          }

          if (choices instanceof Array && arrayPropName) {
            return choices.map((item: T) =>
              renderCustomMenuItem ? (
                renderCustomMenuItem(item)
              ) : (
                <MenuItem
                  value={
                    item[arrayPropId as keyof T] as string | number | readonly string[] | undefined
                  }
                >
                  {item[arrayPropName as keyof T]}
                </MenuItem>
              ),
            );
          }

          return null;
        })()}
      </Select>
      <>
        {showError ? (
          <Stack position="relative" sx={{ mb: 1 }}>
            <Typography
              color="secondaryPink.500"
              variant="body4"
              sx={{ position: 'absolute', mt: 1 }}
            >
              {(fieldState?.error?.message?.length ?? 0) > 0
                ? fieldState?.error?.message
                : 'This field is required'}
            </Typography>
          </Stack>
        ) : (
          ''
        )}
      </>

      {withChips && fieldValue?.length ? (
        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '10px', mt: '16px' }}>
          {fieldValue.map((val: string) => {
            const chipLabel = choices instanceof Map ? choices.get(val) : '';
            return (
              <CustomChip
                key={val}
                label={transformLabelFn ? transformLabelFn(chipLabel) : chipLabel}
                onDelete={() => {
                  setValue(
                    name,
                    fieldValue.filter((item: string) => item !== val),
                  );
                  trigger(name);
                }}
              />
            );
          })}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default ControlledSelectInput;
